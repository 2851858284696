import { Balloon as BalloonType } from './types';
import { BALLOON_SIZE } from './constants';

interface BalloonProps {
  balloon: BalloonType;
}

export const Balloon = ({ balloon }: BalloonProps) => {
  const commonStyles = {
    left: balloon.x,
    top: balloon.y,
    width: `${BALLOON_SIZE}px`,
    height: `${BALLOON_SIZE}px`,
    background: balloon.isMultiColor 
      ? 'linear-gradient(45deg, red, orange, yellow, green, blue, purple)' 
      : balloon.color,
    borderRadius: '50%',
    boxShadow: balloon.isBomb ? '0 0 10px red' : '0 4px 8px rgba(0,0,0,0.2)',
    animation: balloon.isBomb ? 'pulse 1s infinite' : undefined,
    transition: 'transform 0.1s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: balloon.isSpeedControl ? 'white' : undefined,
    fontSize: '20px',
    fontWeight: 'bold',
    position: 'absolute' as const,
  };

  if (balloon.isBomb) {
    return (
      <div style={commonStyles}>
        <div style={{
          position: 'absolute',
          top: '-15px',
          left: '50%',
          transform: 'translateX(-50%)',
          width: '4px',
          height: '12px',
          backgroundColor: '#8B4513',
          borderRadius: '2px',
        }}>
          <div style={{
            position: 'absolute',
            top: '-8px',
            left: '-3px',
            width: '10px',
            height: '10px',
            background: 'radial-gradient(circle, #FFD700, #FF4500)',
            borderRadius: '50%',
            animation: 'flicker 0.3s infinite'
          }} />
        </div>
      </div>
    );
  }

  return (
    <div style={commonStyles}>
      {balloon.speedSymbol}
    </div>
  );
};