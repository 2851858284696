import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { NavBar } from "./components/NavBar/NavBar";
import { Banner } from "./components/Banner/Banner";
import { Skills } from "./components/Skills/Skills";
import { Projects } from "./components/Projects/Projects";
import { Contact } from "./components/Contact/Contact";
import { Footer } from "./components/Footer/Footer";
import FunGame from "./components/Fun/BallonGame/BalloonGame";
import MafiaGame from "./components/Fun/MafiaGame/MafiaGame";
import UnderConstruction from "./components/UnderConstruction/UnderConstruction";

const Home = () => {
  const underConstruction = true;
  return underConstruction ? (
    <UnderConstruction />
  ) : (
    <>
      <NavBar />
      <Banner />
      <Skills />
      <Projects />
      <Contact />
      <Footer />
    </>
  );
};

function App() {
  return (
    <Router>
      <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/kiddo" element={<FunGame />} />
            <Route path="/mafia" element={<MafiaGame />} />
          </Routes>
      </div>
    </Router>
  );
}

export default App;