import React, { useEffect, useState } from 'react';
import { Card, Button, Form, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { 
  setGameCodeValid, 
  setCurrentUser, 
  setPlayers, 
  setLoading, 
  VoteCount,
  setError 
} from '../../../store/slices/gameMafiaSlice';
import { MafiaService } from '../../../api/services/mafia';
import PlayersDeck from './components/PlayersDeck';
import Navbar from './components/Navbar';
import { socketService } from '../../../services/socketService';

interface MafiaGameProps {
  isGameCodeValid: boolean;
  loading: boolean;
  error: string | null;
  currentUser: {
    id: number | null;
    name: string;
    authenticated: boolean;
    vote: number | null;
  } | null;
  players: Array<{
    id: number;
    name: string;
  }>;
  votes: VoteCount[];
  setGameCodeValid: (valid: boolean) => void;
  setCurrentUser: (user: any) => void;
  setPlayers: (players: any[]) => void;
  setLoading: (loading: boolean) => void;
  setError: (error: string | null) => void;
}

const MafiaGame: React.FC<MafiaGameProps> = ({
  isGameCodeValid,
  loading,
  error,
  currentUser,
  players,
  votes,
  setGameCodeValid,
  setCurrentUser,
  setPlayers,
  setLoading,
  setError
}) => {
  const [gameCode, setGameCode] = useState('');

  const validateCode = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    
    if (gameCode.length <= 6) {
      setError('Game code must be at least 6 characters long');
      setLoading(false);
      return;
    }

    try {
      const codeResponse = await MafiaService.verifyCode(gameCode);
      
      if (codeResponse.success) {
        const [userResponse, playersResponse] = await Promise.all([
          MafiaService.getUserInfo(),
          MafiaService.getAllPlayers()
        ]);

        if (userResponse.success && playersResponse.success) {
          setCurrentUser(userResponse.user);
          setPlayers(playersResponse.players);
          setGameCodeValid(true);
          
          // Initialize socket connection after successful login
        //   socketService.connect();
        }
      }
    } catch (err) {
      setError('Failed to join game. Please try again.');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

//   useEffect(() => {
//     return () => {
//       socketService.disconnect();
//     };
//   }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f8f9fa',
      }}
    >
      {isGameCodeValid ? (
        <>
            <Navbar />
            <PlayersDeck 
            currentUser={currentUser}
            players={players}
            votes={votes}
            />
        </>
      ) : (
        <Card
            style={{
                width: '18rem',
                textAlign: 'center',
                padding: '25px',
            }}
        >
            <Card.Title>ENTER GAME CODE</Card.Title>
            <Card.Body>
                <Form onSubmit={validateCode}>
                <Form.Group className="mb-3" controlId="gameCodeInput">
                    <Form.Control
                    type="password"
                    placeholder="Enter game code"
                    value={gameCode}
                    onChange={(e) => setGameCode(e.target.value)}
                    style={{ textAlign: 'center' }}
                    disabled={loading}
                    />
                </Form.Group>
                {error && <Alert variant="danger">{error}</Alert>}
                <Button 
                    variant="primary" 
                    type="submit"
                    disabled={loading}
                >
                    {loading ? 'Joining...' : 'Submit'}
                </Button>
                </Form>
            </Card.Body>
        </Card>
      )}
    </div>
  );
};

// Container component to connect to Redux
const MafiaGameContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { 
    isGameCodeValid, 
    loading, 
    error, 
    currentUser, 
    players,
    votes
  } = useSelector((state: RootState) => state.GameMafia);

  return (
    <MafiaGame
      isGameCodeValid={isGameCodeValid}
      loading={loading}
      error={error}
      currentUser={currentUser}
      players={players}
      votes={votes}
      setGameCodeValid={(valid) => dispatch(setGameCodeValid(valid))}
      setCurrentUser={(user) => dispatch(setCurrentUser(user))}
      setPlayers={(players) => dispatch(setPlayers(players))}
      setLoading={(loading) => dispatch(setLoading(loading))}
      setError={(error) => dispatch(setError(error))}
    />
  );
};

export default MafiaGameContainer;