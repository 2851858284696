import { useState, useRef, useEffect } from 'react';
import { 
  SANTA_ANIMATION_SCORES, 
  SANTA_START_POSITION, 
  MAX_SPARKLES, 
  SPARKLE_COLORS
} from './constants';
import { Sparkle } from './types';
import santaSound from '../../../assets/audio/santa-claus-laughing.mp3';

export const useSantaAnimation = (score: number) => {
  const [santaPosition, setSantaPosition] = useState(SANTA_START_POSITION);
  const [sparkles, setSparkles] = useState<Sparkle[]>([]);
  const santaAnimationRef = useRef<NodeJS.Timeout>();
  const triggeredScores = useRef<Set<number>>(new Set());
  const isAnimatingRef = useRef(false);
  const sparkleIdRef = useRef(0);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const createSparkle = (x: number) => {
    const randomY = Math.random() * 100 + 90;
    return {
      id: sparkleIdRef.current++,
      x,
      y: randomY,
      size: Math.random() * 4 + 2,
      opacity: 1,
      color: SPARKLE_COLORS[Math.floor(Math.random() * SPARKLE_COLORS.length)]
    };
  };

  const playAudio = async () => {
    try {
      if (audioRef.current) {
        audioRef.current.currentTime = 0;
        await audioRef.current.play();
      }
    } catch (error) {
      console.error('Audio play failed:', error);
    }
  };

  const stopAudio = () => {
    try {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    } catch (error) {
      console.error('Audio stop failed:', error);
    }
  };

  useEffect(() => {
    const initAudio = () => {
      if (!audioRef.current) {
        audioRef.current = new Audio(santaSound);
      }
    };

    initAudio();
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        stopAudio();
      } else if (isAnimatingRef.current) {
        playAudio();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (isAnimatingRef.current) return;

    const nextMilestone = SANTA_ANIMATION_SCORES.find(
      milestone => score >= milestone && !triggeredScores.current.has(milestone)
    );

    if (nextMilestone && !santaAnimationRef.current) {
      triggeredScores.current.add(nextMilestone);
      isAnimatingRef.current = true;
      setSantaPosition(SANTA_START_POSITION);
      let position = SANTA_START_POSITION;
      
      playAudio();
      
      santaAnimationRef.current = setInterval(() => {
        position += 5;
        setSantaPosition(position);
        
        setSparkles(prevSparkles => {
          const newSparkles = [...prevSparkles];
          
          if (newSparkles.length < MAX_SPARKLES) {
            newSparkles.push(createSparkle(position));
          }
          
          return newSparkles
            .map(sparkle => ({
              ...sparkle,
              x: sparkle.x - 2,
              opacity: sparkle.opacity - 0.02,
            }))
            .filter(sparkle => sparkle.opacity > 0);
        });
        
        if (position > window.innerWidth) {
          if (santaAnimationRef.current) {
            clearInterval(santaAnimationRef.current);
            santaAnimationRef.current = undefined;
          }
          stopAudio();
          setSparkles([]);
          isAnimatingRef.current = false;
        }
      }, 50);
    }

    return () => {
      if (santaAnimationRef.current && !isAnimatingRef.current) {
        clearInterval(santaAnimationRef.current);
        santaAnimationRef.current = undefined;
        stopAudio();
        setSparkles([]);
      }
    };
  }, [score]);

  return {
    santaPosition,
    sparkles,
    isAnimating: isAnimatingRef.current
  };
}; 