 
export const COLORS = ['blue', 'red', 'green', 'yellow', 'purple', 'orange'];
export const BALLOON_SIZE = 50;
export const CIRCLE_RADIUS = 30;
export const INITIAL_SPAWN_INTERVAL = 1000;
export const MIN_SPAWN_INTERVAL = 50;
export const INITIAL_SPEED = 2;
export const MAX_SPEED = 25;
export const MIN_SPEED = 1;
export const DIFFICULTY_INCREASE_INTERVAL = 10000;
export const INITIAL_BOMB_CHANCE = 0.05;
export const MAX_BOMB_CHANCE = 0.3;
export const MULTI_COLOR_CHANCE = 0.1;
export const SPEED_CONTROL_CHANCE = 0.1;
export const SPEED_CHANGE_FACTOR = 1.5;
export const SANTA_ANIMATION_SCORES = [500, 1000, 1500, 2000] as const;
export const SANTA_START_POSITION = -350;
export const SPARKLE_COLORS = ['#FFD700', '#FFF', '#FFA500', '#FF69B4'];
export const MAX_SPARKLES = 50;
