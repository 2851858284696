import React from "react";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

export default function PlayerCard({ playerName, playerId, votes, votedPlayers, onVoteClick, isVotedByMe }) {
  const avatarUrl = "https://api.dicebear.com/9.x/adventurer/svg?seed=" + playerName;

  return (
    <Card 
      style={{ 
        width: '200px', 
        margin: '10px',
        cursor: 'pointer',
        border: isVotedByMe ? '2px solid #007bff' : '1px solid rgba(0,0,0,.125)'
      }}
      onClick={() => onVoteClick(playerId)}
    >
      <Card.Body>
        <Card.Title>{playerName}</Card.Title>
        <img
          src={avatarUrl}
          alt="avatar"
          style={{ width: '100%', height: 'auto' }}
        />
      </Card.Body>
      <Card.Footer style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
        {votedPlayers && votedPlayers.map((voter) => (
          <OverlayTrigger
            key={voter.userId}
            placement="top"
            overlay={<Tooltip>{voter.userName}</Tooltip>}
          >
            <img
              src={`https://api.dicebear.com/9.x/adventurer/svg?seed=${voter.userName}`}
              alt={`${voter.userName}'s avatar`}
              style={{ width: '30px', height: '30px', borderRadius: '50%' }}
            />
          </OverlayTrigger>
        ))}
      </Card.Footer>
    </Card>
  );
}
