export const API_ENDPOINTS = {
  MAFIA_GAME: {
    VERIFY_CODE: '/verify-code',
    USER_INFO: '/user/info',
    ALL_PLAYERS: '/players',
    UPDATE_NAME: '/register-name',
    LOG_OUT: '/logout',
    VOTE: '/vote',
    GET_VOTES: '/all-votes',
    // Admin only
    ASSIGN_ROLES: '/games/mafia/assign-roles',
    KILL_PLAYER: '/games/mafia/add-dead-player',
    RESET_VOTES: '/delete-votes',
    RESET_DB: '/reset-users'
  },
} as const; 