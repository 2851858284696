import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { setCurrentUser, setMyRole, setPlayers, setVotes } from '../../../../store/slices/gameMafiaSlice';
import { MafiaService } from '../../../../api/services/mafia';

import { ArrowRightCircle } from 'react-bootstrap-icons';


interface NavbarProps {
  loading?: boolean;
}

export const Navbar: React.FC<NavbarProps> = ({ loading = false }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.GameMafia.currentUser);
  const [showNameModal, setShowNameModal] = useState(false);
  const [showRolesModal, setShowRolesModal] = useState(false);
  const [name, setName] = useState('');
  const [killers, setKillers] = useState(0);
  const [doctors, setDoctors] = useState(0);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!name.trim()) {
      setError('Name cannot be empty');
      return;
    }

    try {
      const response = await MafiaService.updateName(name);
      if (response.success && response.user) {
        dispatch(setCurrentUser(response.user));
        setShowNameModal(false);
        setError(null);
      }
    } catch (error) {
      setError('Failed to update name. Please try again.');
      console.error('Error updating name:', error);
    }
  };

  const handleClearVotes = async () => {
    try {
      const response = await MafiaService.resetVotes();
      if (response.success) {
        dispatch(setVotes([]));
      }
    } catch (error) {
      console.error('Error clearing votes:', error);
    }
  };

  const handleResetDB = async () => {
    try {
      await MafiaService.resetDB();
      alert('Database reset successfully.');
    } catch (error) {
      console.error('Error resetting database:', error);
    }
  };

  const handleAssignRoles = () => {
    setShowRolesModal(true);
  };

  const handleGenerateRoles = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await MafiaService.assignRoles(killers, doctors);
      alert('Roles assigned successfully.');
      setShowRolesModal(false);
    } catch (error) {
      console.error('Error assigning roles:', error);
    }
  };

  const handleFetchUserInfo = async () => {
    try {
      const response = await MafiaService.getUserInfo();
      if (response.success) {
        const role = response.user?.role;
        dispatch(setMyRole(role));
        alert(role ? `Your role is ${role}` : 'Roles are not assigned yet');
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  const updateVotesAndPlayers = async () => {
    const votesResponse = await MafiaService.getAllVotes();
    dispatch(setVotes(votesResponse.votes));
    const playersResponse = await MafiaService.getAllPlayers();
    dispatch(setPlayers(playersResponse.players)); 
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <span className="navbar-brand">Mafia</span>
          <div className="navbar-nav ms-auto">
            <Button variant="outline-primary" onClick={updateVotesAndPlayers} className="me-2">
              Refresh
            </Button>
            <Button 
              variant="info" 
              onClick={() => setShowNameModal(true)}
              disabled={loading}
              className="me-2"
            >
              {currentUser?.name || 'Set Name'}
            </Button>
            {currentUser?.name === 'KVNP' ? (
              <>
                <Button variant="warning" onClick={handleClearVotes} disabled={loading} className="me-2">
                  Clear Votes
                </Button>
                <Button variant="danger" onClick={handleResetDB} disabled={loading} className="me-2">
                  Reset DB
                </Button>
                <Button variant="success" onClick={handleAssignRoles} disabled={loading} className="me-2">
                  Assign Roles
                </Button>
              </>
            ) : null}
            <Button variant="secondary" onClick={handleFetchUserInfo} disabled={loading}>
              My Role
            </Button>
          </div>
        </div>
      </nav>

      <Modal show={showNameModal} onHide={() => {setShowNameModal(false); setName(''); setError(null);}}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Your Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder={currentUser?.name || 'Enter your name'}
                value={name}
                onChange={(e) => setName(e.target.value)}
                isInvalid={!!error}
              />
              <Form.Control.Feedback type="invalid">
                {error}
              </Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showRolesModal} onHide={() => setShowRolesModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Generate Roles</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleGenerateRoles}>
            <Form.Group className="mb-3">
              <Form.Label>Number of Killers</Form.Label>
              <Form.Control
                type="number"
                value={killers}
                onChange={(e) => setKillers(Number(e.target.value))}
                min="0"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Number of Doctors</Form.Label>
              <Form.Control
                type="number"
                value={doctors}
                onChange={(e) => setDoctors(Number(e.target.value))}
                min="0"
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Generate Roles
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Navbar;