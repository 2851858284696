import React, { useState } from "react";
import PlayerCard from "./PlayerCard";
import { MafiaService } from "../../../../api/services/mafia";
import { useDispatch } from "react-redux";
import { setVotes } from "../../../../store/slices/gameMafiaSlice";

export default function PlayersDeck({ currentUser, players, votes }) {
  // const [showGenerateRoleModal, setShowGenerateRoleModal] = useState(false);
  // const [showRoleModal, setShowRoleModel] = useState(false);
  // const [killers, setKillers] = useState(0);
  // const [doctors, setDoctors] = useState(0);
  const dispatch = useDispatch();

  // const handleCloseGenerateRolesModal = () => setShowGenerateRoleModal(false);

  // const handleShowRoleModal = () => setShowRoleModel(true);
  // const handleCloseRoleModal = () => setShowRoleModel(false);

  // const handleSetKillersAndDoctors = () => {}

  const computePlayerVotes = (playerId) => {
    return votes.filter(vote => vote.votedToUserId === playerId);
  }

  const handleVote = async (playerId) => {
    try {
      await MafiaService.vote(playerId);
      const votesResponse = await MafiaService.getAllVotes();
      if (votesResponse.success && votesResponse.votes) {
        dispatch(setVotes(votesResponse.votes));
      }
    } catch (error) {
      console.error("Error voting:", error);
    }
  };

  const getVotedPlayers = (playerId) => {
    return votes.filter(vote => vote.votedToUserId === playerId)
      .map(vote => ({
        userId: vote.userId,
        userName: vote.userName
      }));
  };


  return (
    <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
          backgroundColor: "#f8f9fa",
          borderBottom: "1px solid #ddd",
        }}
      >
        <Button variant="info" onClick={handleShowRoleModal}>What am I?</Button>
      </div> */}

      <div style={{ marginTop: "20%" }}>

        <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
          {players.map((player, index) => (
            <PlayerCard 
              key={index} 
              playerId={player.id} 
              playerName={player.name} 
              votes={computePlayerVotes(player.id)}
              votedPlayers={getVotedPlayers(player.id)}
              onVoteClick={handleVote}
              isVotedByMe={computePlayerVotes(player.id)}
            />
          ))}
        </div>

      </div>

      {/* <Modal show={showGenerateRoleModal} onHide={handleCloseGenerateRolesModal}>
        <Modal.Header closeButton>
          <Modal.Title>Generated Roles</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form onSubmit={handleSetKillersAndDoctors}>
            <Form.Group className="mb-3" controlId="nameInput">
              <Form.Control
                type="text"
                placeholder="Number of Killers"
                value={killers}
                onChange={(e) => setKillers(e.target.value)}
              />
              <Form.Control
                type="text"
                placeholder="Number of Doctors"
                value={doctors}
                onChange={(e) => setDoctors(e.target.value)}
              />
            </Form.Group>
            
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseGenerateRolesModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}

      {/* <Modal show={showRoleModal} onHide={handleCloseRoleModal}>
        <Modal.Header closeButton>
          <Modal.Title>You are....</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{currentUser ? currentUser.name : "Some Role"}</p>
        </Modal.Body>
      </Modal> */}
    </div>
  );
}