import React from "react";

import constructionImage from "../../assets/img/construction.png";

const UnderConstruction = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "black", height: "100vh" }}>
      <img src={constructionImage} alt="Under Construction" style={{ width: "60%", height: "auto", maxWidth: "100%", aspectRatio: "4 / 3" }} />
    </div>
  );
};

export default UnderConstruction;
