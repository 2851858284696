import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Player {
  id: number;
  name: string;
}

interface CurrentUser {
  id: number;
  name: string;
  authenticated: boolean;
  vote: number | null;
}

export interface VoteCount {
  userId: number;
  userName: string;
  votedToUserId: number;
  votedToUserName: string;
}

interface MafiaState {
  currentUser: CurrentUser | null;
  players: Player[];
  votes: VoteCount[];
  myRole: string | null;
  isGameCodeValid: boolean;
  loading: boolean;
  error: string | null;
}

const initialState: MafiaState = {
  currentUser: null,
  players: [],
  votes: [],
  myRole: null,
  isGameCodeValid: false,
  loading: false,
  error: null,
};

const GameMafiaSlice = createSlice({
  name: 'mafia',
  initialState,
  reducers: {
    setGameCodeValid: (state, action: PayloadAction<boolean>) => {
      state.isGameCodeValid = action.payload;
    },
    setCurrentUser: (state, action: PayloadAction<CurrentUser>) => {
      state.currentUser = action.payload;
    },
    setPlayers: (state, action: PayloadAction<Player[]>) => {
      state.players = action.payload;
    },
    setVotes: (state, action: PayloadAction<VoteCount[]>) => {
      state.votes = action.payload;
    },
    setMyRole: (state, action: PayloadAction<string | null>) => {
      state.myRole = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
});

export const { 
  setGameCodeValid, 
  setCurrentUser, 
  setPlayers, 
  setVotes,
  setMyRole,
  setLoading, 
  setError 
} = GameMafiaSlice.actions;
export default GameMafiaSlice.reducer;